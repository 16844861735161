<template>

  <v-card
      elevation="2"
      outlined
      class="mx-auto w-100 statusBoardCard"
  >
    <v-card-text class="pa-0">
      <div
          class="position-relative">
        <v-overlay

            :value="overlay"
            opacity="0.8"
        >
          <div class="text-center">
            <v-progress-circular
                indeterminate
                color="primary"
            ></v-progress-circular>
          </div>
          <div>
            데이터 로딩 중입니다. <br/>
            잠시만 기다려주세요.
          </div>
        </v-overlay>
        <v-row no-gutters
               v-if="Object.keys(dashBoardItem).length >0"
        >
          <!--        충환전-->
          <v-col
              cols="4"
          >
            <div class="statusBox"

            >
              <div class="num_box">
                <ul class="co_test">
                  <li class="statusHeader pt-3">
                    충전
                  </li>
                  <li
                      class="cursor-pointer"
                      :class="dashBoardItem.chargeInfo.requestedCount > 0 ? 'alert blink' : ''"
                      @click="move('/deposit/REQUEST')">
                    <span class="c_red">신규</span>
                    <p class="c_show c_red"><span class="size_txt c_red"
                                                  :class="dashBoardItem.chargeInfo.requestedCount > 0 ? 'blink' : ''">
                      {{ dashBoardItem.chargeInfo.requestedCount | comma }}
                    </span>건</p>
                  </li>
                  <li
                      class="cursor-pointer"
                      @click="move('/deposit/CHECK')">
                    <span class="c_blue">대기</span>
                    <p class="c_show"><span class="size_txt">
                      {{ dashBoardItem.chargeInfo.checkedCount | comma }}
                      </span>건</p>
                  </li>
                  <li
                      class="cursor-pointer"
                      @click="move('/deposit/DONE')">
                    <span class="c_blue">완료</span>
                    <p class="c_show"><span class="size_txt">
                      {{ dashBoardItem.chargeInfo.completedCount | comma }}
                      </span>건</p>
                  </li>
                  <li
                      class="cursor-pointer"
                      @click="move('/deposit/REJECT')">
                    <span class="c_blue">취소</span>
                    <p class="c_show"><span class="size_txt">
                      {{ dashBoardItem.chargeInfo.rejectedCount | comma }}</span>건</p>
                  </li>

                  <li style="min-width:120px">
                    <span class="c_blue">총충전액</span>
                    <p class="c_show">
                      <span class="size_txt">
                        {{ dashBoardItem.chargeInfo.totalAmount | comma }}
                    </span>원</p>
                  </li>
                </ul>
              </div>
              <v-divider class=""></v-divider>
              <div class="num_box bottom">
                <ul class="co_test">
                  <li class="statusHeader pt-3">
                    환전
                  </li>
                  <li
                      class="cursor-pointer"
                      @click="move('/withdrawal/REQUEST')"
                      :class="dashBoardItem.exchangeInfo.requestedCount > 0 ? 'alert blink' : ''"
                  >
                    <span class="c_red">신규</span>
                    <p class="c_show c_red"><span class="size_txt c_red"
                                                  :class="dashBoardItem.exchangeInfo.requestedCount> 0 ? 'blink' : ''">
                    {{ dashBoardItem.exchangeInfo.requestedCount | comma }}
                    </span>건</p>
                  </li>
                  <li
                      class="cursor-pointer"
                      @click="move('/withdrawal/CHECK')">
                    <span class="c_blue">대기</span>
                    <p class="c_show"><span class="size_txt">
                    {{ dashBoardItem.exchangeInfo.checkedCount | comma }}
                  </span>건</p>
                  </li>
                  <li
                      class="cursor-pointer"
                      @click="move('/withdrawal/DONE')">
                    <span class="c_blue">완료</span>
                    <p class="c_show"><span class="size_txt">
                    {{ dashBoardItem.exchangeInfo.completedCount | comma }}
                  </span>건</p>
                  </li>
                  <li
                      class="cursor-pointer"
                      @click="move('/withdrawal/REJECT')">
                    <span class="c_blue">취소</span>
                    <p class="c_show"><span class="size_txt">
                    {{ dashBoardItem.exchangeInfo.rejectedCount | comma }}
                  </span>건</p>
                  </li>

                  <li style="min-width:120px">
                    <span class="c_blue">총환전액</span>
                    <p class="c_show"><span class="size_txt">
                    {{ dashBoardItem.exchangeInfo.totalAmount | comma }}
                    </span>원</p>
                  </li>
                </ul>
              </div>
            </div>
          </v-col>

          <v-col
              cols="4"
          >
            <div class="statusBox">
              <div class="num_box">
                <ul class="co_test">
                  <li class="statusHeader pt-3">
                    회원
                  </li>
                  <li
                      class="cursor-pointer"
                      @click="$move('/member')"
                      :class="dashBoardItem.memberInfo.newCount > 0 ? 'alert blink' : ''"
                  >
                    <span class="c_red">신규</span>
                    <p class="c_show c_red"><span class="size_txt c_red"
                                                  :class="dashBoardItem.memberInfo.newCount > 0 ? 'blink' : ''">
                    {{ dashBoardItem.memberInfo.newCount | comma }} / {{
                        dashBoardItem.memberInfo.readyCount | comma
                      }}</span>명</p>
                  </li>
                  <li

                      class="cursor-pointer"
                      @click="move('/member')">
                    <span class="c_blue">총회원</span>
                    <p class="c_show"><span class="size_txt">
                    {{ dashBoardItem.memberInfo.totalCount | comma }}
                    </span>명</p>
                  </li>
                  <li
                      class="cursor-pointer"
                      @click="move('/logOn')">
                    <span class="c_red">로그인</span>
                    <p class="c_show c_red"><span class="size_txt c_red">
                    {{ dashBoardItem.memberInfo.now | comma }}
                    </span>명</p>
                  </li>
                  <li style="min-width:120px">
                    <span class="c_blue">게임머니</span>
                    <p class="c_show"><span class="size_txt">
                    {{ dashBoardItem.memberInfo.totalMoney | comma }}
                    </span>원</p>
                  </li>
                  <li style="min-width:120px">
                    <span class="c_blue">포인트</span>
                    <p class="c_show"><span class="size_txt">
                    {{ dashBoardItem.memberInfo.totalMileage | comma }}
                    </span>P</p>
                  </li>
                </ul>
              </div>
              <v-divider class=""></v-divider>
              <div class="num_box bottom">
                <ul class="co_test">
                  <li class="statusHeader pt-0">
                    <p>Q&A<br>게시판</p>
                  </li>
                  <li
                      class="cursor-pointer"
                      @click="move('/customer')"
                      :class="dashBoardItem.customerCenterInfo.newCount > 0 ? 'alert blink' : ''"
                  >
                    <span class="c_red">신규</span>
                    <p class="c_show c_red"><span class="size_txt c_red"
                                                  :class="dashBoardItem.customerCenterInfo.newCount > 0 ? 'blink' : ''">
                    {{ dashBoardItem.customerCenterInfo.newCount | comma }} / {{ dashBoardItem.customerCenterInfo.checkedCount | comma }}
                    </span>건</p>
                  </li>
                  <li>
                    <span class="c_red">완료</span>
                    <p class="c_show c_red"><span class="size_txt c_red">
                    {{ dashBoardItem.customerCenterInfo.completedCount | comma }}
                    </span>건</p>
                  </li>
                  <li>
                    <span class="c_red">게시물</span>
                    <p class="c_show c_red"><span class="size_txt c_red">
                    {{ dashBoardItem.customerCenterInfo.postCount | comma }}
                    </span>개</p>
                  </li>

                  <li style="min-width:120px">
                    <!--                  <span class="c_blue">게임머니(대리점)</span>
                                      <p class="c_show"><span class="size_txt">
                                        {{dashBoardItem.memberInfo.totalAgencyMoney | comma}}
                                        </span>원</p>-->
                  </li>
                  <li style="min-width:120px">
                    <!--                  <span class="c_blue">포인트(대리점)</span>
                                      <p class="c_show"><span class="size_txt">
                                        {{dashBoardItem.memberInfo.totalAgencyMileage | comma}}
                                        </span>P</p>-->
                  </li>
                </ul>
              </div>
            </div>
          </v-col>

          <v-col
              cols="4"
          >
            <div class="statusBox">
              <div class="num_box">
                <ul class="co_test betting">
                  <li class="statusHeader pt-6">
                    <p class="c_red">베팅회원</p>
                    <p class="c_red">베팅중</p>
                    <p class="c_red">총베팅</p>
                    <p class="c_red">베팅정산</p>
                  </li>
                  <li>
                    <span class="c_blue">카지노</span>
                    <p class="c_show c_red"><span class="size_txt c_red">
                    {{ dashBoardItem.betInfo.casinoLiveBetMembers | comma }}
                    </span>명</p>
                    <p class="c_show c_red"><span class="size_txt c_red">
                    {{ dashBoardItem.betInfo.casinoLiveBetAmount | comma }}
                    </span>원</p>
                    <p class="c_show c_red"><span class="size_txt c_red">
                    {{ dashBoardItem.betInfo.casinoLiveTotalAmount | comma }}
                   </span>원</p>
                    <p class="c_show c_red"><span class="size_txt c_red">
                    {{ dashBoardItem.betInfo.casinoLiveProfitAmount | comma }}
                    </span>원</p>
                  </li>
                  <li>
                    <span class="c_blue">슬롯</span>
                    <p class="c_show c_red"><span class="size_txt c_red">
                    {{ dashBoardItem.betInfo.casinoSlotBetMembers | comma }}
                    </span>명</p>
                    <p class="c_show c_red"><span class="size_txt c_red">
                    {{ dashBoardItem.betInfo.casinoSlotBetAmount | comma }}
                    </span>원</p>
                    <p class="c_show c_red"><span class="size_txt c_red">
                    {{ dashBoardItem.betInfo.casinoSlotTotalAmount | comma }}
                   </span>원</p>
                    <p class="c_show c_red"><span class="size_txt c_red">
                    {{ dashBoardItem.betInfo.casinoSlotProfitAmount | comma }}
                    </span>원</p>

                  </li>

                  <li>
                  </li>
                  <li>
                  </li>
                </ul>
              </div>
            </div>
            <div v-html="soundCharge"></div>
            <div v-html="soundExchange"></div>
            <div v-html="soundCustomer"></div>
            <div v-html="soundMember"></div>
          </v-col>
        </v-row>
      </div>
    </v-card-text>
  </v-card>
</template>
<script>
// import axios from "axios";


export default {
  name: 'StatusBoard',
  data: () => ({

    duration: 1,
    scaleClass: true,

  }),
  methods: {

    move: function (item) {
      this.$emit('move', item)
    },

  },

  mounted() {
  },


  props: {
    dashBoardItem: {
      type: Object
    },
    soundCharge: {
      type: String
    },
    soundExchange: {
      type: String
    },
    soundCustomer: {
      type: String
    },
    soundMember: {
      type: String
    },
    overlay: {
      type: Boolean
    }

  },
  computed: {
    themeFontColor: function () {
      return this.vuetify.theme.dark ? 'black-font' : ''
    },
  },

}
</script>

<style>
.theme--light.v-card > .v-card__text {
  color: #333333 !important;
}

.theme--dark.v-card > .v-card__text {
  color: #FFFFFF !important;
}

.num_box li {
  min-width: 40px;
}

.statusBox {
  height: 138px;
  //border-radius: 10px;
  margin-right: 0px;
  box-shadow: 3px 3px 9px 3px #2c2c2c;
  background-color: #322f2f;
  overflow: hidden;
  font-family: GmarketSansLight;
  font-size: 13px;
}

.statusBox .num_box.bottom {
  background-color: #444141;
}

.statusBox .num_box .co_test {
  width: 100%;
  height: 52px;
  display: flex;
  align-items: normal;
  justify-content: space-around;
  padding: 6px 0px;
}

.statusBox li {
  list-style: none
}

.statusBox li p {
  font-size: 12px
}

.statusHeader {
  font-family: GmarketSansMedium;
  font-size: 13px;
  min-width: 30px;
}

.betting li:not(:first-child) {
  height: 120px;
  background-color: #444141;
  padding: 1px 5px;
  box-shadow: 0px 1px 2px #656060;
  min-width: 110px;
}

.betting li p {
  line-height: 8px;
  margin-top: 6px;
}

.statusBox {
  margin: 1px;
}
.num_box {
  height: 69px;
}
.alert {

  color: gold;


}

.blink {
  color: gold !important;
}

</style>
